import React, { useState, useEffect, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import Divider from '@mui/material/Divider';
import { doc, setDoc } from "firebase/firestore"; 
import db from '../util/firebase';
import { useTranslation } from 'react-i18next';
import AuthContext from "../util/authcontext";
import { Colors, DarkColors, Languages } from '../util/constants';

export default function Language() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;

    function exit() {
        navigate("/");
    }

    function selectLanguage(code) {
        if (user) {
            // const docRef = doc(db, 'users', user.uid);
            // setDoc(docRef, { languagecode: code }, { merge: true });
            // i18n.changeLanguage(code);

            const sourcelanguagecode = language?.sourcelanguagecode
            if (code == sourcelanguagecode) {
                if (code == "en") {
                    setLanguage({languagecode: code, sourcelanguagecode: "tr"})
                }
                else if (code == "tr") {
                    setLanguage({languagecode: code, sourcelanguagecode: "en"}) 
                }
            }
            else {
                setLanguage({languagecode: code, sourcelanguagecode: language?.sourcelanguagecode})
            }
            exit();
        }
        else {
            navigate("/login");
        }
    }

    useEffect(() => {
        document.body.style.background = Colors[14];
    }, []);

    return(
        <><IconButton aria-label="exit" size="large" sx={{ m: 4, backgroundColor: DarkColors[14] }} onClick={exit}>
            <CloseIcon fontSize="inherit" />
        </IconButton>
        <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
            <Stack spacing={4} sx={{ width: '90%'}}>
                <h2>Öğrenilecek dil</h2>
                <List>
                    {Languages.map((value) => {
                        return (
                            <><ListItem
                                key={value.code}>
                                <ListItemButton onClick={() => selectLanguage(value.code)}>
                                    <ListItemAvatar>
                                        <img src={require('../assets/flags/' + value.code + 'flag.svg')} style={{ borderRadius: '50%' }} width='60' height='60' alt={`flag${value}`}></img>
                                    </ListItemAvatar>
                                    <ListItemText disableTypography id={value.code} primary={value.name} sx={{ ml: 5, fontSize: 25 }} />
                                </ListItemButton>
                            </ListItem><Divider /></>
                        );
                    })}
                </List>
            </Stack>
        </Container></>
    )
}
export const Colors = ['#f4aca5', '#eb73a1', '#ba68c8', '#7e57c2', '#64b4f6', '#60b3d1', '#4cc4d9', '#9ccc65', '#9e9d24', '#ffb300', '#f9a825', '#ff6f43', '#ff8a65', '#ef5350', '#f47672'];
export const DarkColors = ['#fd9598', '#e36690', '#9a56b0', '#7351ae', '#569bdb', '#56a4bc', '#45b7da', '#8ab241', '#8c8b1f', '#e39f00', '#ed9820', '#eb663d', '#f0825d', '#e64744', '#c8605c'];
export const LightColors = ['#00000011'];
export const ComplementaryColors = ['#a5f7f4', '#73ebbd', '#9a56b0', '#7351ae', '#569bdb', '#56a4bc', '#d47463', '#aa9dcc', '#8c8b1f', '#5079d9', '#ed9820', '#a5f7f4', '#a5f7f4', '#a5f7f4', '#73ebbd'];
export const ComplementaryLightColors = ['#a5f7f4', '#73ebbd', '#9a56b0', '#7351ae', '#569bdb', '#56a4bc', '#d47463', '#aa9dcc', '#8c8b1f', '#5079d9', '#ed9820', '#a5f7f4', '#a5f7f4', '#a5f7f4', '#84fcce'];
export const TintColor = 'rgba(0,0,0,0.5)';
export const OtherColors = ['#ffe7c5', '#ee5e5f', '#a3f4e2'];
export const OtherColorsDark = ['#c2ad97', '#ba4a4a', '#4df0cc'];
export const Languages = [{code: 'en', name: 'İngilizce'}, {code: 'de', name: 'Almanca'}];
export const SourceLanguages = [{code: 'tr', name: 'Türkçe'}];
export const MAX_LIVES = 5;
export const LIVES_LOCK_S = 1800
export const INSTAGRAM_URL = "https://www.instagram.com/fonoyayinlari";
export const YOUTUBE_URL = "https://www.youtube.com/channel/UCvLNTmPcdN4fYGetF_gzRPg";
export const TIKTOK_URL = "https://www.tiktok.com/@fonoyayinlari";
export const BADGE_THRESHOLDS = [10.0, 30.0, 50.0, 70.0];

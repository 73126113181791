import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import React, { useState, useEffect } from 'react';
import styles from './explanation.module.css';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import ContinueButton from './continuebutton';
import Play from './play.js'
import Pause from './pause.js'

export default function Explanation(props) {
    const field = props.field
    const fields = props.fields
    const color = props.color;
    const soundenabled = props.soundenabled;
    const [correctaudio, setCorrectAudio] = useState();
    const [incorrectaudio, setInCorrectAudio] = useState();
    const languagecode = props.languagecode;

    function increaseOrder() {
        let order = props.order;
        order++;
        props.onNextExercise(order);
        if (correctaudio != null) {
            correctaudio.play();
          }
    }

    function AudioControls(props) {
      const field = props.field
      const index = props.index

      const [isPlaying, setIsPlaying] = useState(false);

      function play(index) {
        const audio = document.getElementById("audio" + index);
    
        audio.play();

        setIsPlaying(true)
  
        audio.onended = function() {
          setIsPlaying(false)
        }
      }
    
      function pause(index) {
        const audio = document.getElementById("audio" + index);
    
        audio.pause()
  
        setIsPlaying(false)
      }

      return(
        <>
        {field.audio != "" &&  <><audio id={'audio' + index}>
          <source src={field.audio} />
          Your browser does not support the <code>audio</code> element.
        </audio><div className={styles.audiocontrol}>
            {isPlaying == true ?
              <Pause handleClick={() => pause(index)} /> :
              <Play handleClick={() => play(index)} />}
        </div></>}
        <div dangerouslySetInnerHTML={{ __html: field.name }} />
        </>
      )
    }

    useEffect(() => {
        if (soundenabled) {
          setCorrectAudio(new Audio(correctsound));
          setInCorrectAudio(new Audio(incorrectsound));
        }
      }, []);

      useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Enter') {
            increaseOrder()
          }
        };
    
        // Add the event listener to the window or document
        window.addEventListener('keydown', handleKeyDown);
    
        // Clean up the event listener when the component is unmounted
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }, [correctaudio, field]);

    return(
        <Stack spacing={10} sx={{alignItems: 'center', width: '100%', maxWidth: '800px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card className={styles.explanationbox} sx={{width: '100%', height:500, padding: '30px', backgroundColor: color + '.dark', overflow: 'scroll'}}>
            <div dangerouslySetInnerHTML={{ __html: field.name }} />
              <table className={styles.explanationtable}>
                <tbody>
                  {fields.filter(x => x.order > 1).map((field, index) =>  {
                      return (
                          <tr key={index}>
                          <td><AudioControls field={field} index={index} /></td>
                          {field.translation !== "" && 
                          <td>{field.translation}</td>}
                          </tr>
                      )
                  })}
                  </tbody>
              </table>
            </Card>
            <ContinueButton color={color} onClick={increaseOrder} />
        </Stack>
    )
}
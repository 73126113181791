import React, { useRef, createRef, useState, useEffect, useContext } from 'react';
import styles from './lessongrid.module.css';
import { Link } from "react-router-dom";
import LessonButton from './lessonbutton'
import { Colors, DarkColors } from '../util/constants'
import Stack from '@mui/material/Stack';

export default function LessonGrid(props) {
    const sections = props.sections;
    const finishedlessons = props.finishedlessons;
    const url = props.url;
    const refs = useRef({})

    function getColors(index) {
        const color1 = Colors[index % Colors.length]
        const color2 = DarkColors[index % Colors.length]
        return [color1, color2]
    }

    function LessonRow(props) {
        const section = props.section
        const index = props.index
        const backgroundcolors = getColors(index)
        let sectionlessons = section.data.lessons
        let lessontypes = section.data.lessontypes

        return (
            <div key={"sectionwrapper" + index} className={styles.sectionbox} style={{backgroundColor: backgroundcolors[0]}}>
                <Stack alignItems="center" spacing={8}>
                    <h2 key={index} className={styles.sectiontitle}>{section.data.name}</h2>
                    <img src={require('../assets/' + section.data.image + '.png')} className={styles.sectionimage}>
                            </img>
                    <div key={"sectiondiv" + index} className={styles.hs}>
                    <Stack direction="row" spacing={5}>
                        {sectionlessons.map((lesson, lessonindex) => (
                             <Link key={"link" + lesson} className={styles.lessonlink} to={url} state={{ lessonid: lesson, lessontype: section.data.lessontypes[lessonindex] }}>
                                <div ref={el => (refs.current = { ...refs.current, [lesson]: el })}>
                                    <LessonButton key={"lesson" + lesson} color={backgroundcolors[1]} lessonname={"Ders " + (lessonindex + 1)} lessontype={lessontypes[lessonindex]} isfinished={finishedlessons.includes(lesson)}></LessonButton>
                                </div>
                             </Link>
                        ))}
                    </Stack>
                    </div>
                </Stack>
            </div>
        )
    }

    useEffect(() => {
        let arrlength = finishedlessons.length
        if (arrlength > 0) {
            const currentRef = refs.current[finishedlessons[arrlength - 1]];
            if (currentRef) {
                currentRef.scrollIntoView({ behavior: 'instant', block: 'center' });
            }
        }
    }, []);

    return (
        <div className={styles.lessongrid}>
            {sections.length > 0 &&
                sections.map((section, index) => (
                    section.data.name &&
                    <LessonRow key={"section" + index} className={styles.lessonrow} section={section} index={index} />
            ))}
        </div>
    )
}
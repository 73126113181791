import Stack from '@mui/material/Stack';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MatchButton from './matchbutton';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import selectsound from '../assets/sounds/select.mp3';

export default function Match(props) {
    const exercisefield = props.field
    const word = {name: exercisefield.name, translation: exercisefield.translation, order: exercisefield.order}
    const words = props.fields.map(x =>  ({name: x.name, translation: x.translation, order: x.order }));
    const color = props.color;
    const soundenabled = props.soundenabled;
    const exercisecounter = props.exercisecounter

    const [leftwords, setLeftWords] = useState([]);
    const [rightwords, setRightWords] = useState([]);
    const [iswrong, setIsWrong] = useState(false);
    const [correctaudio, setCorrectAudio] = useState();
    const [incorrectaudio, setInCorrectAudio] = useState();
    const [selectaudio, setSelectAudio] = useState();
    const [selectedfield, setSelectedField] = useState(null);
    const [selecteddirection, setSelectedDirection] = useState(null);
    const [removedfields, setRemovedFields] = useState([])

    function increaseOrder(iswrong) {
        let order = props.order;
        order++;
        props.onNextExercise(order, iswrong, "match");
    }

    function checkAnswer(direction, index) {
        if (JSON.stringify(removedfields).includes(JSON.stringify([direction, index])) == false) {
            const field = direction === 0 ? leftwords[index] : rightwords[index]
            if (selectedfield == null) {
                if (selectaudio != null) {
                    selectaudio.play();
                }
                let newremovedfields = removedfields
                let _ = newremovedfields.push([direction, index])
                setRemovedFields(newremovedfields)
                setSelectedDirection(direction)
                setSelectedField(field)
            }
            else if (direction !== selecteddirection) {
                if (field.order == selectedfield.order) {
                    if (removedfields.length + 2 > leftwords.length * 2) {
                        let newremovedfields = removedfields
                        let _ = newremovedfields.push([direction, index])
                        setRemovedFields(newremovedfields)
                        setSelectedField(field)
                        if (correctaudio != null) {
                            correctaudio.play();
                        }
                        setTimeout(function () {
                            increaseOrder();
                        }, 600);
                    }
                    else {
                        let newremovedfields = removedfields
                        let _ = newremovedfields.push([direction, index])
                        setRemovedFields(newremovedfields)
                        setSelectedField(null)
                        setSelectedDirection(null)
                    }
                }
                else {
                    if (incorrectaudio != null) {
                        incorrectaudio.play();
                    }
                    setIsWrong(true)
                    setTimeout(function () {
                        increaseOrder(true);
                  }, 600);
                }
            }
        }
    }

    function shuffle(array) {
        let currentIndex = array.length
        let randomIndex = 0
      
        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
    }

    function uniqueByField(array, field) {
        const uniqueValues = new Set();
        return array.filter((item) => {
          const value = item[field];
          if (!uniqueValues.has(value)) {
            uniqueValues.add(value);
            return true;
          }
          return false;
        });
      }
      
    useEffect(() => {
        if (soundenabled) {
            setCorrectAudio(new Audio(correctsound));
            setInCorrectAudio(new Audio(incorrectsound));
            setSelectAudio(new Audio(selectsound));
        }

        const _otherwords = words.filter(_word => _word.order !== word.order && _word.translation !== word.translation &&  _word.name !== word.name);
        const _otherwordsunique = uniqueByField(_otherwords, 'translation');
        const _allwords = _otherwordsunique.slice(0,3)
        _allwords.push(word)
        const allwords = shuffle(_allwords);
        let leftwords = shuffle(allwords)
        let rightwords = shuffle(JSON.parse(JSON.stringify(leftwords)))
        setLeftWords(leftwords)
        setRightWords(rightwords)

        return () => {
            setIsWrong(false)
            setSelectedField(null)
            setSelectedDirection(null)
            setRemovedFields([])
        }
    }, [exercisefield, exercisecounter]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === '1') {
                selecteddirection === 0 ? checkAnswer(1, 0) : checkAnswer(0, 0)
            }
            if (event.key === '2') {
                selecteddirection === 0 ? checkAnswer(1, 1) : checkAnswer(0, 1)
            }
            if (event.key === '3') {
                selecteddirection === 0 ? checkAnswer(1, 2) : checkAnswer(0, 2)
            }
            if (event.key === '4') {
                selecteddirection === 0 ? checkAnswer(1, 3) : checkAnswer(0, 3)
            }
        };
    
        // Add the event listener to the window or document
        window.addEventListener('keydown', handleKeyDown);
    
        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        }, [leftwords, rightwords, selecteddirection, selectedfield, selectaudio]);

    return(
        <Stack spacing={10} sx={{width: '100%', maxWidth: '500px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h2>Kelimeleri çevirileriyle eşleştir.</h2>
            <Box sx={{width: '100%', height:500, padding: '30px', backgroundColor: 'transparent'}}>
                <Stack direction="row" justifyContent={'space-between'}>
                    <Stack spacing={5} sx={{m: 2, width: '100%', maxWidth: '200px'}}>
                    {leftwords.map((field, index) => (
                     <div onClick={(e) => checkAnswer(0, index, e)}>
                        <MatchButton key={"left" + index} color={color} iswrong={iswrong} direction={0} field={field} isremoved={JSON.stringify(removedfields).includes(JSON.stringify([0, index]))} />
                    </div>
                    ))}
                    </Stack>
                    <Stack spacing={5} sx={{m: 2, width: '100%', maxWidth: '200px'}}>
                    {rightwords.map((field, index) => (
                     <div onClick={(e) => checkAnswer(1, index, e)}>
                     <MatchButton key={"right" + index} color={color} iswrong={iswrong} direction={1} field={field} isremoved={JSON.stringify(removedfields).includes(JSON.stringify([1, index]))} />
                        </div>
                    ))}
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}
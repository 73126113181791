import styles from './livestext.module.css';

export default function LivesText(props) {
    const lives = props.lives && props.lives > 0 ? "♥".repeat(props.lives) : "";
    const premium = props.premium
    
    if (premium) {
        return(
            <div className={styles.livestext}>∞</div>
        )  
    }
    else {
    return(
        <div className={styles.livestext}>{lives}</div>
    )
    }
}

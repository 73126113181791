import LessonGrid from '../components/lessongrid'
import NavBar from '../components/navbar'
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../util/authcontext";
import firebaseApp from '../util/firebase';
import { Colors, OtherColors } from '../util/constants';
import { collection, getDocs, query, orderBy, getFirestore } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import styles from './home.module.css'

export default function Home() {
    const navigate = useNavigate();
    const { uservalue, languagevalue, loaded, togglelogin } = useContext(AuthContext);
    const [user, setUser] = uservalue
    const [language, setLanguage] = languagevalue
    const [login, setLogin] = togglelogin;
    const [sections, setSections] = useState([]); 
    const [finishedlessons, setFinishedLessons] = useState([]);
    const db = getFirestore(firebaseApp);

    const fetchData = async () => {
        const sectionsdocumentname = "sections" + language.languagecode + language.sourcelanguagecode
        const q1 = query(collection(db, sectionsdocumentname), orderBy("order"));
        const querySnapshot1 = await getDocs(q1);
        const sections = querySnapshot1.docs.map(doc => ({id: doc.id, data: doc.data()}));
        setSections(sections); 
     }

    useEffect(() => {
        document.body.style.background = Colors[14]
    }, []);

    useEffect(() => {
        if (!user?.profile && loaded && !login) {
            navigate("/login");
        }
        else if (user?.profile && loaded) {
            fetchData();
            setFinishedLessons(user?.profile?.finishedlessons)
        }
    }, [user, loaded, login]);

    return(
        <div className={styles.container}>
            {user?.profile && loaded && (
                <>
                    <NavBar 
                        languagecode={language?.languagecode} 
                        vocabularycount={user?.profile?.vocabularycount} 
                        percentage={user?.profile?.percentage} 
                        avatarurl={user?.authuser?.photoURL} 
                    />
                    {sections.length > 0 && (
                        <LessonGrid 
                            sections={sections} 
                            finishedlessons={finishedlessons} 
                            url={'/game'} 
                            colors={Colors} 
                        />
                    )}
                    {/* Uncomment and modify the following line if you intend to use the RightPanel component */}
                    {/* <RightPanel 
                        percentage={user?.profile?.percentage} 
                        vocabularycount={user?.profile?.vocabularycount} 
                        datesofactivity={user?.profile?.datesofactivity} 
                    /> */}
                </>
            )}
        </div>
    )
}

import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getFirestore, arrayUnion, collection, doc, getDocs, query, updateDoc, where, limit } from "firebase/firestore";
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import EndOfLesson from '../components/endoflesson';
import ExitButton from '../components/exitbutton';
import Fill from '../components/fill';
import GamePanel from '../components/gamepanel';
import NewGame from '../components/newgame';
import NoLives from '../components/nolives';
import Explanation from '../components/explanation';
import Match from '../components/match';
import SelectImage from '../components/selectimage';
import SocialMedia from '../components/socialmedia';
import Vocabulary from '../components/vocabulary';
import WordPuzzle from '../components/wordpuzzle';
import YesNo from '../components/yesno';
import AuthContext from "../util/authcontext";
import firebaseApp from '../util/firebase';
import { Colors, DarkColors, LightColors, MAX_LIVES, OtherColors, OtherColorsDark, TintColor, LIVES_LOCK_S } from '../util/constants';

const theme = createTheme({
    palette: {
      primary: {
        main: Colors[0],        
        dark: DarkColors[0],
        light: LightColors[0],
      },
      secondary: {
        main: Colors[1],        
        dark: DarkColors[1],
        light: LightColors[0],
      },
      error: {
        main: Colors[6],        
        dark: DarkColors[2],
        light: LightColors[0],
      },
      warning: {
        main: Colors[8],        
        dark: DarkColors[3],
        light: LightColors[0],
      },
      success: {
        main: OtherColors[1],
        dark: OtherColorsDark[1], 
        light: LightColors[0] 
      },
      info: {
        main: TintColor
      }
    },
  });

export default function Game() {
    const location = useLocation();
    const { lessonid, lessontype } = location.state;
    const [exerciseorder, setExerciseOrder] = useState(0);
    const [lesson, setLesson] = useState(null);
    const [fieldindex, setFieldIndex] = useState(0);
    const [palette, setPalette] = useState('primary');
    const [fields, setFields] = useState(null);
    const [field, setField] = useState({});
    const [remainingfields, setRemainingFields] = useState([]);
    const [nextexercise, setNextExercise] = useState(null);
    const { uservalue, languagevalue, loaded } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const db = getFirestore(firebaseApp);
    const [premium, setPremium] = useState(false)
    const [lives, setLives] = useState(MAX_LIVES)

    const navigate = useNavigate();
    const soundenabled = (localStorage.getItem("Sound_Enabled") ?? "true") === "true" ? true : false;

    function goToNextExercise(neworder, iswrong = false, exercise = null) {

        var _profile = user?.profile;
        var _user = user?.user

        let remaininglives = lives

        if (!iswrong && !premium) {
            const newlives = calculateLives(_profile);

            if (newlives != lives) {
                remaininglives = newlives
                setLives(newlives)
                setUser({ user: _user, profile: _profile });
                updateProfile(_user.userid, _profile);
            }
        }

        if (iswrong) {
            if (exercise != null) {
                let _remainingfields = remainingfields;

                _remainingfields.push({ exercise: exercise, field: field});
                setRemainingFields(_remainingfields);
            }

            if (!premium) {
                if (lives > 0) {
                    remaininglives -= 1
                    setLives(remaininglives);
                }

                let currenttime = new Date().getTime()
                const wrongdate = Math.floor(currenttime / 1000)

                if (_profile?.lifelossdates.length >= MAX_LIVES) {
                    _profile?.lifelossdates.unshift(wrongdate)
                    _profile?.lifelossdates.splice(MAX_LIVES)
                }
                else {
                    _profile?.lifelossdates.push(wrongdate)
                }

                setUser({ user: _user, profile: _profile });
                updateProfile(_user.userid, _profile)
            }
        }

        let nextexercise = GameScreen.NewGame;
        let currentexercisefield = null;
        let currentfields = [];

        if (fields.length !== 0 && fieldindex > fields.length - 1 && nextexercise != GameScreen.EndOfLesson && neworder < 1001) {
            nextexercise = GameScreen.EndOfLesson
            neworder = 1000
        }

        if (remaininglives == 0 && !premium) {
            nextexercise = GameScreen.NoLives;
        }
        else {
            if (neworder == 0) {
                neworder = 1
            }

            let decidedexercise = decideNextExercise(neworder, lessontype)
            nextexercise = decidedexercise.gamescreen
            neworder = decidedexercise.currentexerciseorder
            currentexercisefield = decidedexercise.currentfield
            currentfields = buildFields(nextexercise, fields)
        }

        if (neworder == 1001) {
            const finishedlessons = _profile?.finishedlessons;
            const lessonid = lesson.id;

            if (!finishedlessons.includes(lessonid)) {
                _profile.percentage += lesson.points;
                _profile.vocabulary += lesson.vocabularycount;
                _profile.finishedlessons.push(lessonid);

                updateProfile(_user.userid, _profile, true);
            }
        }

        setExerciseOrder(neworder);
        setField(currentexercisefield)
        setFields(currentfields)
        setNextExercise(nextexercise);
        setExerciseOrder(exerciseorder + 1);
    }

    const updateProfile = async (userid, profile, islast = false) => {
        const docRef = doc(db, "users", userid, "profiles", language);

        if (islast) {
            const currenttime = new Date().getTime()
            const currentdate = Math.floor(currenttime / (1000 * 3600 * 24))
            await updateDoc(docRef, {
                finishedlessons: arrayUnion(lesson.id),
                percentage: profile.percentage,
                vocabulary: profile.vocabulary,
                datesofactivity: arrayUnion(currentdate)
            });
        }
        else {
            await updateDoc(docRef, {
                lifelossdates: profile?.lifelossdates
            });
        }
    }

    function calculateLives(profile) {

        const lifelossdates = profile?.lifelossdates

        let currenttime = new Date().getTime()
        const currenttimeseconds = Math.floor(currenttime / 1000)

        var lives = MAX_LIVES

        if (lifelossdates != null && lifelossdates.length > 0) {
            lifelossdates.forEach(function (losstime) {
                let timedifference = currenttimeseconds - losstime

                if (timedifference < LIVES_LOCK_S) {
                    lives -= 1
                }
            })
        }

        return lives
    }

    function decideNextExercise(neworder, type) {
        let currentfield = ""
        let gamescreen = GameScreen.NewGame;
        let currentexerciseorder = neworder
        let typeenumvalue = getTypeFromValue(type)
        let screens = allScreens[typeenumvalue]

        if (currentexerciseorder !== 0) {
            if (currentexerciseorder < screens.length && fieldindex < fields.length) {
                gamescreen = screens[currentexerciseorder];
                if (gamescreen === GameScreen.Vocabulary || gamescreen === GameScreen.Phrase) {
                    if (currentexerciseorder >= 2) {
                        setFieldIndex(fieldindex + 1);
                    }
                } else if (lessontype === LessonType.Grammar) {
                    if (gamescreen === GameScreen.FillPhrase) {
                        setFieldIndex(fieldindex + 1);
                    }
                } else if (lessontype === LessonType.Review || lessontype === LessonType.GeneralReview) {
                    if (currentexerciseorder >= 2) {
                        setFieldIndex(fieldindex + 1);
                    }
                }
        
                currentfield = fields[fieldindex];
            } else if (remainingfields.length > 0) {
                currentexerciseorder -= 1;
                gamescreen = remainingfields[0].screen;
                currentfield = remainingfields[0].field;
                remainingfields.shift();
            } else {
                gamescreen = GameScreen.EndOfLesson;
            }
        } 
        
        return { gamescreen, currentexerciseorder, currentfield }
    }

    function buildFields(gamescreen, fields) {
        if (gamescreen == GameScreen.Match) {
            let exercisefields = fields.filter(field => field.translation !== "").sort(() => Math.random() - 0.5).slice(0, 5);
            let leftfields = exercisefields;
            let rightfields = [...leftfields].sort(() => Math.random() - 0.5);
            let exerciseFields = leftfields.concat(rightfields);

            return exerciseFields
        }

        return fields
    }

    function getRemainingField(nextexercise, currentfield, neworder) {
        const remainingexercise = remainingfields.shift();
        nextexercise = remainingexercise.exercise;
        currentfield = remainingexercise.field;
        neworder -= 1;
        return { nextexercise, currentfield, neworder };
    }

    function exit() {
        navigate("/");
    }

    function getTypeFromValue(value) {
        const key = Object.keys(LessonType).find(key => LessonType[key] === value);
        return LessonType[key];
    }
    

    useEffect(() => {
        const fetchLesson = async () => {
            if (!lessonid || !language?.languagecode || !language?.sourcelanguagecode) {
                console.log('Required values are not defined');
                return;
            }

            const documentname = "lessons" + language?.languagecode + language?.sourcelanguagecode
            const q = query(
                collection(db, documentname),
                where("order", "==", lessonid),
                limit(1)  
            );

            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty && querySnapshot.docs[0]) {
                // Get the first document from the query result
                const result = querySnapshot.docs[0].data();
                setLesson(result);
            }  else {
                // Handle case where no document is found
                console.log('Not found')
                setLesson(null);
            }
         }

         const fetchFields = async () => {
            const documentname = "fields" + language?.languagecode + language?.sourcelanguagecode
            const q = query(collection(db, documentname), where("lessonid", "==", lessonid));
            const querySnapshot = await getDocs(q);
            const result = querySnapshot.docs.map(doc => doc.data());
            setFields(result);  
         }
       
         fetchLesson();
         fetchFields();
    }, []);

    useEffect(() => {
        if (user?.user && user?.profile && loaded) {
            const _profile = user.profile
            const _user = user.user

            if (_user?.premium) {
                setPremium(true)
            }
            else {
                setLives(calculateLives(_profile))
            }
        }
    }, [user, loaded]);

    useEffect(() => {
        if (exerciseorder >= 1000) {
            setPalette('success');
            document.body.style.background = theme.palette.success.main; 
        }
        else if (nextexercise === GameScreen.NoLives) {
            document.body.style.background = OtherColors[0];
        }
        else {
        switch (exerciseorder % 4) {
            case 0: 
            setPalette('primary');
            document.body.style.background = theme.palette.primary.main;
            break;
            case 1: 
            setPalette('secondary');
            document.body.style.background = theme.palette.secondary.main;
            break;
            case 2: 
            setPalette('error');
            document.body.style.background = theme.palette.error.main;
            break;
            case 3: 
            setPalette('warning');
            document.body.style.background = theme.palette.warning.main;
            break;
            default: 
            setPalette('success');
            document.body.style.background = theme.palette.success.main;
        }
        }
      }, [exerciseorder]);


    if (exerciseorder === 0) {
        return (
            <>
            {loaded &&
                <ThemeProvider theme={theme}>
                    <ExitButton color={palette + '.dark'} onExit={exit} />
                    <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                        <NewGame color={palette} lesson={lesson} order={exerciseorder} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                    </Container>
                </ThemeProvider>
            }</>
        )
    }
    else if (nextexercise === GameScreen.NoLives) {
        return (
            <>
            {loaded &&
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <NoLives profile={user?.profile} soundenabled={soundenabled} onNextExercise={goToNextExercise} exit={exit} />
                </Container>
            }</>
        )
    }
    else if (nextexercise === GameScreen.Explanation) {
        return (
            <>
            {fields && loaded && 
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} premium={premium} fieldindex={fieldindex} fields={fields} exit={exit} />     
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <Explanation color={palette} order={exerciseorder} fields={fields} field={field} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
            }
            </>
        )
    }
    else if (nextexercise === GameScreen.Match) {
        return (
            <>
            {fields && loaded && 
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} premium={premium} fieldindex={fieldindex} fields={fields} exit={exit} />     
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <Match color={palette} order={exerciseorder} fields={fields} field={field} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
            }
            </>
        )
    }
    else if (nextexercise === GameScreen.Vocabulary) {
        return (
            <>
            {fields && loaded && 
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} premium={premium} fieldindex={fieldindex} fields={fields} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <Vocabulary color={palette} order={exerciseorder} field={field} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider> 
            }
            </>
        )
    }
    else if (nextexercise === GameScreen.YesNo) {
        return (
            <>
            {fields && loaded && 
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} premium={premium} fieldindex={fieldindex} fields={fields} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <YesNo color={palette} order={exerciseorder} fields={fields} field={field} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
            }
            </>
        )
    }
    else if (nextexercise === GameScreen.SelectImage) {
        return (
            <>
            {fields && loaded && 
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} premium={premium} fieldindex={fieldindex} fields={fields} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <SelectImage color={palette} order={exerciseorder} fields={fields} field={field} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
            }
            </>
        )
    }

    else if (nextexercise === GameScreen.Fill) {
        return (
            <>
            {fields && loaded && 
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} premium={premium} fieldindex={fieldindex} fields={fields} exit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <Fill color={palette} order={exerciseorder} fields={fields} field={field} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
            }
            </>
        )
    }

    else if (nextexercise === GameScreen.WordPuzzle) {
        return (
            <>
            {fields && loaded && 
            <ThemeProvider theme={theme}>
                <GamePanel color={palette + '.dark'} lives={user?.profile?.lives} premium={premium} fieldindex={fieldindex} fields={fields} exit={exit} /> 
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <WordPuzzle color={palette} order={exerciseorder} field={field} soundenabled={soundenabled} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
            }
            </>
        )
    }

    else if (nextexercise === GameScreen.EndOfLesson) {
        return (
            <ThemeProvider theme={theme}>
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <EndOfLesson user={user} fields={fields} order={exerciseorder} onNextExercise={goToNextExercise} />
                </Container>
            </ThemeProvider>
        )
    }

    else if (nextexercise === GameScreen.SocialMedia) {
        return (
            <ThemeProvider theme={theme}>
                <ExitButton color='success.dark' onExit={exit} />
                <Container sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center'}} className="game-container">
                    <SocialMedia />
                </Container>
            </ThemeProvider>
        )
    }
    else {
        return null;
    }

}

const LessonType = {
    Grammar: 1,
    Words: 2,
    Phrases: 3,
    Review: 4,
    GeneralReview: 5
};

const GameScreen = {
    NewGame: 'NewGame',
    Explanation: 'Explanation',
    Match: 'Match',
    FillPhrase: 'FillPhrase',
    MultipleChoice: 'MultipleChoice',
    Listen: 'Listen',
    SocialMedia: 'SocialMedia',
    Speech: 'Speech',
    Vocabulary: 'Vocabulary',
    YesNo: 'YesNo',
    SelectImage: 'SelectImage',
    Fill: 'Fill',
    WordPuzzle: 'WordPuzzle',
    Phrase: 'Phrase'
};

const allScreens = {
    [LessonType.Grammar]: [
        GameScreen.NewGame, GameScreen.Explanation, GameScreen.Match, GameScreen.FillPhrase, GameScreen.MultipleChoice, GameScreen.FillPhrase, GameScreen.Match, GameScreen.FillPhrase, GameScreen.Match, GameScreen.Listen, GameScreen.FillPhrase, GameScreen.Speech, GameScreen.MultipleChoice
    ],
    [LessonType.Words]: [
        GameScreen.NewGame, GameScreen.Vocabulary, GameScreen.YesNo, GameScreen.Vocabulary, GameScreen.SelectImage, GameScreen.Fill, GameScreen.Vocabulary, GameScreen.WordPuzzle, GameScreen.Fill, GameScreen.YesNo, GameScreen.Vocabulary, GameScreen.SelectImage, GameScreen.WordPuzzle, GameScreen.Fill
    ],
    [LessonType.Phrases]: [
        GameScreen.NewGame, GameScreen.Phrase, GameScreen.MultipleChoice, GameScreen.Phrase, GameScreen.Listen, GameScreen.Speech, GameScreen.Phrase, GameScreen.Speech, GameScreen.MultipleChoice, GameScreen.Listen, GameScreen.Phrase, GameScreen.MultipleChoice, GameScreen.Listen, GameScreen.Speech
    ],
    [LessonType.Review]: [
        GameScreen.NewGame, GameScreen.Match, GameScreen.Fill, GameScreen.MultipleChoice, GameScreen.Listen, GameScreen.Speech, GameScreen.FillPhrase, GameScreen.Match, GameScreen.Listen
    ],
    [LessonType.GeneralReview]: [
        GameScreen.NewGame, GameScreen.Match, GameScreen.Fill, GameScreen.WordPuzzle, GameScreen.Listen, GameScreen.WordPuzzle, GameScreen.YesNo, GameScreen.Match, GameScreen.Listen
    ]
};
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../components/navbar';
import AuthContext from "../util/authcontext";
import { OtherColors } from '../util/constants';
import db from '../util/firebase';

export default function Collection() {
    const { uservalue, languagevalue } = useContext(AuthContext);
    const [user, setUser] = uservalue;
    const [language, setLanguage] = languagevalue;
    const [words, setWords] = useState([]);
    const [filteredwords, setFilteredWords] = useState([]);

    const fetchData = async () => {
        if (user != null) {
        const finishedlessons = user?.profile?.finishedlessons;
        const documentname = "words" + language?.languagecode + language?.sourcelanguagecode

        if (finishedlessons?.length > 0) {
            const finishedchunk = sliceIntoChunks(finishedlessons, 10);
            let wordsarray = [];

            for (let i = 0; i < finishedchunk.length; i++) {
                const q = query(collection(db, documentname), where('lessonid', 'in', finishedchunk[i]));
                const querySnapshot = await getDocs(q);
                const result = querySnapshot.docs.map(doc => ({id: doc.id, data: doc.data()}));
                wordsarray = wordsarray.concat(result);

                if (i == finishedchunk.length - 1) {
                    setWords(wordsarray);  
                    setFilteredWords(wordsarray);
                }
            }
        }
        }
     }

    useEffect(() => {
        fetchData();
    }, [user]);

    useEffect(() => {
        document.body.style.background = OtherColors[0];
    }, []);

    function speak(word) {
        
    }

    function sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }
    

    function textChange(newtext) {
        const filteredwords = words.filter((word) => word.data.name.includes(newtext) == true ||  word.data.translation.includes(newtext) == true);
        setFilteredWords(filteredwords);
    }

    return (
        <><NavBar languagecode={language?.languagecode} avatarurl={user?.authuser?.photoURL} />
        <Container sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
        <Stack spacing={4} sx={{ width: '90%'}}>
            <TextField id="filled-basic" label="Search" variant="filled" onChange={(e) => textChange(e.target.value)} />
            {filteredwords.length > 0 &&
                <List>
                {filteredwords.map((word) => {
                    return (
                        <><ListItem
                            key={word.id}>
                            <ListItemButton onClick={() => speak({ text: word.data.name })}>
                                <ListItemText id={word.id} primary={word.data.name} secondary={word.data.translation} primaryTypographyProps={{fontSize: '24px'}} secondaryTypographyProps={{fontSize: '18px'}}/>
                                <ListItemAvatar>
                                    <img src={require('../assets/' + word.data.image + '.png')} width='60' height='60' alt={word.data.name}></img>
                                </ListItemAvatar>
                            </ListItemButton>
                        </ListItem></>
                    );
                })}
                </List>
                }
        </Stack>
        </Container></>
    )
}
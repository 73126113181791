import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import correctsound from '../assets/sounds/correct.mp3';
import incorrectsound from '../assets/sounds/notcorrect.mp3';
import styles from './yesno.module.css';

export default function YesNo(props) {

    const words = props.words;
    const word = props.word;
    const color = props.color;
    const soundenabled = props.soundenabled;

    const [otherword, setOtherWord] = useState({});
    const [wrongansweredyesclicked, setWrongAnsweredYesClicked] = useState(false);
    const [wronganswerednoclicked, setWrongAnsweredNoClicked] = useState(false);
    const [doesmatch, SetDoesMatch] = useState(false);
    const [correctaudio, setCorrectAudio] = useState();
    const [incorrectaudio, setInCorrectAudio] = useState();

    useEffect(() => {
      const otherwords = words.filter(_word => _word.order != word.order);
      const otherword = otherwords[Math.floor(Math.random() * otherwords.length)];
      const probability = Math.floor((Math.random() * 10) + 1);
      if (probability > 4) {
        setOtherWord(otherword);
      }
      else {
        setOtherWord(word);
        SetDoesMatch(true);
      }
      if (soundenabled) {
        setCorrectAudio(new Audio(correctsound));
        setInCorrectAudio(new Audio(incorrectsound));
      }
    }, []);

    function speak(word) {
      
    }

    function increaseOrder(iswrong) {
        let order = props.order;
        order++;
        props.onNextExercise(order, iswrong, {word: word, exercise: "yesno"});
    }

    function yesClicked() {
      if (!doesmatch) {
        setWrongAnsweredYesClicked(true);
        if (incorrectaudio != null) {
          incorrectaudio.play();
        }
        setTimeout(function () {
          increaseOrder(true);
      }, 600);
      }
      else {
        if (correctaudio != null) {
          correctaudio.play();
        }
        increaseOrder();
      }
    }

    function noClicked() {
      if (doesmatch) {
        setWrongAnsweredNoClicked(true);
        if (incorrectaudio != null) {
          incorrectaudio.play();
        }
        setTimeout(function () {
          increaseOrder(true);
      }, 600);
      }
      else {
        if (correctaudio != null) {
          correctaudio.play();
        }
        increaseOrder();
      }
    }

    return(
        <Stack spacing={4}>
        <h2>Kelimenin çevirisi doğru mu?</h2>
        <Box className={styles.yesnocard} sx={{
            height: 450,
            width: 500,
            borderRadius: 1,
            backgroundColor: color + '.dark',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
        <Stack sx={{ alignItems: 'center'}} spacing={6}>
        <img className={styles.wordimage} src={require('../assets/' + word.image + '.png')} width="192">
                        </img>
                        <IconButton size="large" onClick={() => speak({ text: word.name })}>
        <PlayCircleIcon fontSize="inherit" />
        </IconButton>
            <h2>{word.name}</h2>
        </Stack>
        </Box>
        <h1>{otherword.translation}</h1>
        <Stack direction="row" sx={{justifyContent: 'space-between'}}>
        <IconButton className={styles.yesnobutton + (wronganswerednoclicked ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 150, height: 150, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={noClicked}><CloseIcon sx={{ fontSize: 40 }} /></IconButton>
        <IconButton className={styles.yesnobutton + (wrongansweredyesclicked ? " " + styles.wrong : "")} sx={{bgcolor: color + '.dark', color: 'black', fontWeight: 'bold', fontSize: 18, borderRadius: 1, width: 150, height: 150, ':hover': {
      bgcolor: color + '.light'
    }}} onClick={yesClicked}><CheckIcon sx={{ fontSize: 40 }} /></IconButton>
        </Stack>
        </Stack>
    )
}
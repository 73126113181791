import React, { useState } from 'react';
import styles from './lessonbutton.module.css';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

export default function LessonButton(props) {
    const backgroundcolor = props.color
    const lessontype = props.lessontype
    const [isPressed, setIsPressed] = useState(false);

    const handlePress = () => {
        setIsPressed(true);
        // Wait 100ms, then set isPressed back to false
        setTimeout(() => setIsPressed(false), 200);
    };


    const renderIcon = () => {
        switch (lessontype) {
            case 1:
                return <LibraryBooksIcon />
            case 2:
                return <ViewModuleIcon />
            case 3:
                return <FitnessCenterIcon />
            case 4:
                return <ChatBubbleIcon />
            default:
                return <DirectionsRunIcon />
        }
    }

    return(
        <div className={styles.circlecontainer}>
            <div className={styles.circleshadow} style={{ transition: '0.1s',
            transform: isPressed ? 'scale(0.95)' : 'scale(1)', bottom: '-5px'}}></div>
            <div className={styles.circlebutton} 
            style={{ transition: '0.1s',
            transform: isPressed ? 'scale(0.95)' : 'scale(1)', bottom: isPressed ? '-5px' : 0, backgroundColor: backgroundcolor}} onClick={handlePress}>
                {renderIcon()}
            </div>
        </div>
    )
}